import { z } from "zod";

export const documentSenderTypes = [
  "provisory_professional_card",
  "medical_checkup_request_convocation",
  "qualification_training_request_convocation",
  "qualification_training_request_certificate",
] as const;

export const templateSenderTypes = ["medical_checkup_request_medispace"] as const;

export type DocumentSenderType = (typeof documentSenderTypes)[number];

export const documentSenderTalentSchema = z.object({
  talent_id: z.string(),
  document_id: z.string(),
  type: z.enum(documentSenderTypes),
  cc_user_ids: z.array(z.string()).optional(),
});

export const documentSenderContactSchema = z.object({
  contact_id: z.string(),
  document_id: z.string(),
  type: z.enum(documentSenderTypes),
  talent_id: z.string().optional(),
});

export type DocumentSenderTalentBody = z.infer<typeof documentSenderTalentSchema>;
export type DocumentSenderContactBody = z.infer<typeof documentSenderContactSchema>;

export const templateSenderSchema = z.object({
  talent_id: z.string(),
  type: z.enum(templateSenderTypes),
});

export type TemplateSenderBody = z.infer<typeof templateSenderSchema>;

// For the moment we only send emails to talent
// so we don't need to specify the recipient's email, we can get it from the talent_id
export const HtmlEmailSenderSchema = z.object({
  talent_ids: z.array(z.string()),
  auth_user_id: z.string(),
  body: z.string(),
  subject: z.string(),
  tag: z.string(),
});

export type HtmlEmailSenderBody = z.infer<typeof HtmlEmailSenderSchema>;
export const smsSenderSchema = z.object({
  content: z.string(),
  phone_number: z.string(),
  tag: z.string(),
});

export const multipleSmsSenderSchema = z.object({
  content: z.string(),
  phone_numbers: z.array(z.string()),
  tag: z.string(),
});

export type SmsSenderBody = z.infer<typeof smsSenderSchema>;
export type MultipleSmsSenderBody = z.infer<typeof multipleSmsSenderSchema>;
