import { z } from "zod";
import {
  AdvancePaymentRequestStatusSupabaseEnum,
  AdvancePaymentRequestTypeSupabaseEnum,
  ExpenseClaimRefundScheduleSupabaseEnum,
  ExpenseClaimRequestStatusSupabaseEnum,
  ExpenseClaimRequestTypeSupabaseEnum,
  MonthSupabaseEnum,
  PayTrackingStatusSupabaseEnum,
  QualificationTrainingRequestStatusSupabaseEnum,
  TimesheetWeekStatusSupabaseEnum,
} from "../../types/supabase.enum.gen.ts";
import { payTrackingFileSchema } from "../shared.ts";
import { payTrackingAccountingSchema, payTrackingConsultantSchema, payTrackingTalentSchema } from "./shared.schema.ts";

export const payTrackingDetailsFilterSchema = z.object({
  id: z.string(),
});

export const payTrackingDetailsAdvancePaymentRequestSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(AdvancePaymentRequestStatusSupabaseEnum),
  amount: z.number().nonnegative().nullable(),
  real_amount_paid: z.number().nonnegative().nullable(),
  created_at: z.string(),
  consultant: payTrackingConsultantSchema,
  type: z.nativeEnum(AdvancePaymentRequestTypeSupabaseEnum),
  month: z.nativeEnum(MonthSupabaseEnum),
  files: z.array(payTrackingFileSchema).nullable(),
});

export const payTrackingDetailsTimesheetWeekSchema = z.object({
  week_number: z.number().nonnegative(),
  is_worked: z.boolean(),
  status: z.nativeEnum(TimesheetWeekStatusSupabaseEnum),
  total_minutes: z.number().nonnegative(),
  specificity: z
    .object({
      id: z.string().nullable(),
      timesheet_week_id: z.string().nullable(),
      text: z.string().nullable(),
    })
    .nullable(),
  timesheet_week_id: z.string().nullable(),
  contact_comment: z.string().nullable(),
  talent_comment: z
    .array(
      z.object({
        date: z.string(),
        comment: z.string(),
      })
    )
    .nullable(),
});

export const payTrackingDetailsExpenseClaimRequestSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ExpenseClaimRequestStatusSupabaseEnum),
  consultant: payTrackingConsultantSchema,
  files: z.array(payTrackingFileSchema),
  refund_schedule: z.nativeEnum(ExpenseClaimRefundScheduleSupabaseEnum),
  created_at: z.string(),
  amount: z.number().nonnegative(),
  type: z.nativeEnum(ExpenseClaimRequestTypeSupabaseEnum),
});

export const payTrackingDetailsQualificationTrainingRequestSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(QualificationTrainingRequestStatusSupabaseEnum),
  consultant: payTrackingConsultantSchema,
  created_at: z.string(),
  files: z.array(payTrackingFileSchema),
  start_at: z.string(),
  end_at: z.string(),
  price: z.number().nonnegative().nullable(),
  talent_id: z.string(),
  is_paid_during_training: z.boolean(),
});

export const payTrackingDetailsContractSchema = z.object({
  talent: payTrackingTalentSchema,
  contract_key: z.number(),
  start_date: z.string(),
  end_date: z.string(),
  coefficient: z.number().nonnegative(),
  hourly_wage: z.number().nonnegative(),
  is_termination_bonus_paid_monthly: z.boolean().nullable(),
  working_hours: z.string().nullable(),
  overtime: z.string().nullable(),
  consultant: payTrackingConsultantSchema,
  compensation_bonus_with_charges: z.string().nullable(),
  compensation_bonus_without_charges: z.string().nullable(),
  compensation_bonus_others: z.string().nullable(),
  timesheet_weeks: z.array(payTrackingDetailsTimesheetWeekSchema),
  expense_claim_requests: z.array(payTrackingDetailsExpenseClaimRequestSchema).nullable(),
  qualification_training_requests: z.array(payTrackingDetailsQualificationTrainingRequestSchema).nullable(),
  agency: z.object({
    name: z.string(),
    address: z.object({
      formatted_address: z.string().nullable(),
      region_name: z.string().nullable(),
      city: z.string().nullable(),
      country: z.string().nullable(),
      postal_code: z.string().nullable(),
    }),
  }),
});

export const payTrackingDetailsSchema = z.object({
  id: z.string(),
  month: z.nativeEnum(MonthSupabaseEnum),
  year: z.string(),
  status: z.nativeEnum(PayTrackingStatusSupabaseEnum),
  talent: payTrackingTalentSchema,
  accounting: payTrackingAccountingSchema,
  contracts: z.array(payTrackingDetailsContractSchema),
  advance_payment_requests: z.array(payTrackingDetailsAdvancePaymentRequestSchema),
  expense_claim_request_count: z.number().nonnegative().nullable(),
  qualification_training_request_count: z.number().nonnegative().nullable(),
  timesheet_weeks: z.array(payTrackingDetailsTimesheetWeekSchema),
});
