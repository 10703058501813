import z from "zod";

export const timesheetResetBodySchema = z
  .object({
    timesheetWeekId: z.string().uuid(),
  })
  .strict();
export type TimesheetResetBody = z.infer<typeof timesheetResetBodySchema>;
export const timesheetResetResponseSchema = z.object({
  success: z.boolean(),
  error: z.string().nullable(),
});
export type TimesheetResetResponse = z.infer<typeof timesheetResetResponseSchema>;
