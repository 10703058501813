import { z } from "zod";
import type { Ppe, PpeRequestStatus } from "../../utils";

const ppeStatusEnum = ["pending", "approved", "refused", "todo"] as const satisfies PpeRequestStatus[];
const ppeEquipmentEnum = [
  "hearing_protection_helmet",
  "protective_glasses",
  "protective_gloves",
  "safety_helmet",
  "safety_vest",
  "safety_pants",
  "safety_shoes",
  "comfort_parka",
  "comfort_polar_vest",
  "comfort_earplugs",
] as const satisfies Ppe[];

const ppeItemSchema = z.object({
  ppe_item: z.object({
    equipment: z.enum(ppeEquipmentEnum),
    label_fr: z.string(),
  }),
  attribute: z.string(),
});

export const ppeRequestsDetailsSchema = z.object({
  id: z.string(),
  status: z.enum(ppeStatusEnum),
  created_at: z.string(),
  ppe_others: z.string().nullable(),
  contract: z.object({ contract_key: z.number() }),
  ppes: z.array(ppeItemSchema),
});

const ppeItemForInsert = z.object({
  attributes: z.array(z.string()),
  equipment: z.string(),
  id: z.string(),
  label_fr: z.string(),
});

export const ppeRequestFromTalentSchema = z.object({
  contract_key: z.number(),
  ppes: z.array(
    z.object({
      attribute: z.string(),
      ppe_item: ppeItemForInsert,
    })
  ),
});

export const ppeRequestResponseSchema = z.object({
  contract_id: z.string(),
  created_at: z.string(),
  created_by: z.string().nullable(),
  id: z.string(),
  owner_id: z.string().nullable(),
  ppe_others: z.string().nullable(),
  price: z.number().nullable(),
  status: z.enum(ppeStatusEnum),
  talent_id: z.string(),
  updated_at: z.string().nullable(),
  updated_by: z.string().nullable(),
  workspace_id: z.string(),
  wsm_id: z.string().nullable(),
  is_created_from_app: z.boolean(),
});
