import { z } from "zod";

export const payTrackingFileSchema = z.object({
  id: z.string(),
  name: z.string(),
  source: z.enum(["supabase", "filestack"]),
  mimetype: z.string(),
  created_at: z.string(),
  created_by: z.string().nullable(),
  filestack_url: z.string().nullable(),
  supabase_name: z.string(),
  filestack_handle: z.string().nullable(),
  supabase_bucket_id: z.string(),
});
