import { Loader } from "@googlemaps/js-api-loader";
import type { MaybeRef } from "vue";
import type { Address } from "~/utils/supabase.types";

export const useGoogleMapsAutocomplete = function (inputRef: MaybeRef<HTMLInputElement | undefined>) {
  let autocomplete: google.maps.places.Autocomplete;

  const address = ref<Address>();
  const location = ref<string>();

  const placeChangedHook = useEventHook();
  const { loadPlaces, loadCore } = useGoogleMaps();

  onMounted(async () => {
    const places = await loadPlaces();
    const core = await loadCore();

    autocomplete = new places.Autocomplete(unref(inputRef)!, {
      types: ["establishment", "geocode"],
      fields: ["formatted_address", "geometry.location", "address_components"],
      componentRestrictions: { country: ["fr", "MC", "BE", "CH"] }, // Restrict the search to France, Monaco, Belgium, and Switzerland
    });

    core.event.addListener(autocomplete, "place_changed", () => {
      let street1 = "";
      let street2 = "";
      let postalCode = "";
      let country = "";
      let city = "";
      let department = "";
      let region = "";

      const place = autocomplete.getPlace();

      const addressComponents = place.address_components ?? [];
      /**
       * Get each component of the address from the place details,
       * and then fill-in the corresponding field on the form.
       * place.address_components are google.maps.GeocoderAddressComponent objects
       * which are documented at http://goo.gle/3l5i5Mr
       */
      for (const component of addressComponents) {
        const types = component.types;

        if (types.includes("street_number") || types.includes("route")) {
          if (!street1) street1 = component.long_name;
          else street2 = component.long_name;
        } else if (types.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (types.includes("postal_code_suffix")) {
          postalCode = `${postalCode}-${component.long_name}`;
        } else if (types.includes("locality")) {
          city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          region = component.long_name; // Extract the region (e.g., "Île-de-France")
        } else if (types.includes("administrative_area_level_2")) {
          department = component.long_name; // Extract the department (e.g., "Paris")
        } else if (types.includes("country")) {
          country = component.long_name;
        }
      }

      const { geometry } = place;
      const longitude = geometry?.location?.lng();
      const latitude = geometry?.location?.lat();
      const coordinates = { lng: longitude!, lat: latitude! };

      location.value = convertCoordinatesToWkt(coordinates);

      address.value = {
        formatted: place.formatted_address!,
        street1,
        street2,
        city,
        postal_code: postalCode,
        region,
        department,
        country,
        coordinates: coordinates!,
      };

      placeChangedHook.trigger({
        address: address.value,
        location: location.value,
      });
    });
  });

  onUnmounted(async () => {
    if (autocomplete) {
      const core = await loadCore();
      core.event.clearInstanceListeners(autocomplete);
    }
  });

  type PlaceResultHookInput = {
    address: Address;
    location: string;
  };

  function onPlaceChanged(func: (placeResult: PlaceResultHookInput) => void) {
    placeChangedHook.on(func);
  }

  return {
    address,
    location,

    onPlaceChanged,
  };
};

export const useGoogleMapsUtils = function () {
  const searchUrl = function (formattedAddress?: string | null) {
    if (!formattedAddress) return "";

    const encodedFormattedAddress = encodeURIComponent(formattedAddress);
    return `https://www.google.com/maps/search/?api=1&query=${encodedFormattedAddress}`;
  };

  return { searchUrl };
};

export const useGoogleMaps = createSharedComposable(() => {
  const config = useRuntimeConfig();

  const loader = new Loader({
    apiKey: config.public.GOOGLE_MAPS_CLIENT_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  const loadPlaces = async () => await loader.importLibrary("places");
  const loadCore = async () => await loader.importLibrary("core");

  return {
    loadPlaces,
    loadCore,
  };
});
