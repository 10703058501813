import {
  applicationOmogenBaseColumns,
  baseAcquisitionColumns,
  baseAddressTableColumns,
  baseAdvancePaymentRequestColumns,
  baseAgencyColumns,
  baseApplicationColumns,
  baseBusinessUnitColumns,
  baseCommercialConditionColumns,
  baseCompanyColumns,
  baseContactColumns,
  baseContractColumns,
  baseDepartmentColumns,
  baseEmailColumns,
  baseExpenseClaimRequestColumns,
  baseFileColumns,
  baseHiringPoolColumns,
  baseMedicalCenterColumns,
  baseMedicalCheckupRequestColumns,
  baseMissionColumns,
  baseMissionWorkspaceColumns,
  baseNoteColumns,
  baseNotificationColumns,
  basePpeRequestColumns,
  baseProfessionalCardRequestColumns,
  baseProjectTypologyColumns,
  baseQualificationCenterColumns,
  baseQualificationCertificateColumns,
  baseQualificationTrainingRequestColumns,
  baseRecruitmentOfficePaymentColumns,
  baseReferenceCheckColumns,
  baseRegionColumns,
  baseResumeColumns,
  baseSpecialtyColumns,
  baseTalentColumns,
  baseTalentJobColumns,
  baseTalentJobExperienceColumns,
  baseTalentProjectTypologyColumns,
  baseTalentPushMarketingRequestColumns,
  baseTalentSoftwareColumns,
  baseTeamMemberColumns,
  baseWorkspaceColumns,
} from "~/utils/supabase.base-columns.ts";

export const fileColumns = `
  ${baseFileColumns}
` as const;

export const companyColumns = `
  ${baseCompanyColumns},
  company_group(
    id,
    name
  ),
  agencies:agency(
    id
  ),
  missions:mission(
    id
  ),
  contacts:contact(
    id
  )
` as const;

export const companySearchColumns = `
  id,
  name,
  updated_at,
  naf_code_label,
  company_group(
    name
  )
` as const;

export const addressTableColumns = `
  ${baseAddressTableColumns}
` as const;

export const agencyColumns = `
  ${baseAgencyColumns},
  address:address_table(
    ${baseAddressTableColumns}
  ),
  company(
    ${baseCompanyColumns}
  ),
  contracts:contract(
    id
  ),
  missions:mission(
    id
  )
` as const;

export const agencySearchColumns = `
  id,
  name,
  updated_at,
  company(
    name
  )
` as const;

export const companyGroupColumns = `
  id,
  name
` as const;

export const authUserColumns = `
  id,
  first_name,
  last_name,
  email,
  phone_number,
  avatar_file:file!auth_user_avatar_file_id_fkey(
    ${baseFileColumns}
  )
` as const;

export const softwareColumns = `
  *
` as const;

export const talentSoftwareColumns = `
  ${baseTalentSoftwareColumns},
  software(
    ${softwareColumns}
  )
` as const;

export const businessUnitColumns = `
  ${baseBusinessUnitColumns}
` as const;

export const teamMemberForAvatarColumns = `
  ${baseTeamMemberColumns},
  auth_user(
    ${authUserColumns},
    user_role(
      role
    )
  )
` as const;

export const teamMemberResponsibleDuringAbsenceColumns = `
  team_member_taking_over:team_member!team_member_responsible_during__team_member_taking_over_id_fkey!inner(
    ${teamMemberForAvatarColumns}
  ),
  team_member_away_managees,
  previous_manager:team_member!team_member_responsible_during_absence_previous_manager_id_fkey!inner(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const teamMemberColumns = `
  ${teamMemberForAvatarColumns},
  current_workspace:workspace!team_member_workspace_id_fkey(
    ${baseWorkspaceColumns}
  ),
  main_workspace:workspace!public_team_member_main_workspace_id_fkey(
    ${baseWorkspaceColumns}
  ),
  team_member_workspaces:team_member_workspace!inner(
    ${baseTeamMemberColumns},
    workspace(
      ${baseWorkspaceColumns}
    )
  ),
  business_unit(
    ${baseBusinessUnitColumns}
  ),
  management_level,
  is_onboarded
` as const;

export const workspaceColumns = `
  ${baseWorkspaceColumns},
  team_members:team_member!team_member_workspace_id_fkey(
    ${baseTeamMemberColumns}
  )
` as const;

export const contactColumns = `
  ${baseContactColumns},
  company(
    ${baseCompanyColumns}
  ),
  owner:team_member(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const contactWithContractColumns = `
  ${contactColumns},
  contract_contact!inner(
    contract_key
  )
` as const;

export const contactSearchColumns = `
  id,
  first_name,
  last_name,
  email,
  phone_number,
  updated_at,
  workspace_id
` as const;

export const talentJobExperienceColumns = `
  ${baseTalentJobExperienceColumns}
` as const;

export const resumeColumns = `
  ${baseResumeColumns},
  file:file!resume_file_id_fkey(
    ${baseFileColumns}
  )
` as const;

export const referenceCheckColumns = `
  ${baseReferenceCheckColumns},
  contact(
    ${contactColumns}
  ),
  talent!inner(
    ${baseTalentColumns},
    business_units:business_unit (
      ${baseBusinessUnitColumns}
    )
  ),
  created_by:auth_user!reference_check_created_by_fkey(
    ${authUserColumns}
  ),
  updated_by:auth_user!reference_check_updated_by_fkey(
    ${authUserColumns}
  )
` as const;

export const hiringPoolColumns = `
  ${baseHiringPoolColumns},
  business_unit(
    ${baseBusinessUnitColumns}
  )
` as const;

export const hiringPoolForTalentCountColumns = `
  *,
    business_unit(
    ${baseBusinessUnitColumns}
  ),
  talent_count:talent_hiring_pool(count)
` as const;

export const talentForRowColumns = `
  ${baseTalentColumns},
  resume(
    ${baseResumeColumns},
    file:file!resume_file_id_fkey(
      ${baseFileColumns}
    )
  ),
  hiring_pool(
    ${hiringPoolColumns}
  ),
  business_units:business_unit (
    ${baseBusinessUnitColumns}
  ),
  application(
    id,
    stage,
    is_rejected,
    mission_id
  ),
  created_by_user:auth_user!talent_created_by_fkey(
    ${authUserColumns}
  ),
  talent_workspaces:talent_workspace(
    *
  ),
  address_table(
    ${baseAddressTableColumns},
    department(
      code,
      name,
      local_code,
      region(
        code,
        name
      )
    )
  )
` as const;

export const talentColumns = `
  ${talentForRowColumns},
  document(
    qualification_certificate(
      ${baseQualificationCertificateColumns}
    )
  ),
  reference_check(
    ${baseReferenceCheckColumns}
  ),
  talent_job_experience(
    ${baseTalentJobExperienceColumns}
  ),
  talent_job(
    ${baseTalentJobColumns}
  ),
  talent_project_typology(
    project_typology(
      ${baseProjectTypologyColumns}
    )
  ),
  talent_software(
    ${baseTalentSoftwareColumns}
  ),
  updated_by_user:auth_user!talent_updated_by_fkey(
    ${authUserColumns}
  ),
  acquisition(
    ${baseAcquisitionColumns}
  ),
  availability_status_updated_by:auth_user!availability_status_updated_by_fkey(
    ${authUserColumns}
  ),
  owner:team_member!talent_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  talent_resume_data(
    parsed_text,
    standardized_text
  )
` as const;

export const talentSearchColumns = `
  id,
  first_name,
  last_name,
  email,
  phone_number,
  updated_at,
  duplicate_of,
  talent_workspace!inner(*)
` as const;

export const talentOverviewColumns = `
  id,
  first_name,
  last_name,
  email,
  phone_number,
  updated_at,
  address_table(
    ${baseAddressTableColumns}
  ),
  job_name,
  salary_expectation,
  availability_status,
  contract_types,
  is_foreign_candidate,
  talent_workspace!inner(
    *
  )
` as const;

export const contractColumns = `
  ${baseContractColumns},
  talent!inner(
    first_name,
    last_name,
    email,
    phone_number,
    emergency_contact,
    address_table(
      ${baseAddressTableColumns}
    ),
    birth_date
  ),
  job(
    id,
    name
  ),
  mission(
    id,
    formatted_address,
    location, 
    address
  ),
  agency(
    ${agencyColumns}
  ),
  owner:team_member!contract_owner_id_fkey(
    workspace_id,
    manager_id
  ),
  created_by:auth_user!contract_created_by_fkey(
    first_name,
    last_name
  ),
  created_by_team_member:auth_user!contract_created_by_fkey(
    team_member(
        ${teamMemberForAvatarColumns}
    )
  ),
  updated_by:auth_user!contract_updated_by_fkey(
    first_name,
    last_name
  )
` as const;

export const contractForTableColumns = `
  ${baseContactColumns},
  talent!inner(
    id,
    first_name,
    last_name,
    has_auth_access,
    email,
    phone_number
  ),
  created_by:auth_user!contract_created_by_fkey(
    first_name,
    last_name
  ),
  agency(
    name,
    company(
      name
    )
  ),
  owner:team_member!contract_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const contractForDefinitiveEndColumns = `
  id,
  is_definitive_end,
  real_end_date,
  is_termination_bonus_to_be_paid,
  definitive_end_type,
  definitive_end_details,
  definitive_end_processed_at,
  is_definitive_end_processed,
  definitive_end_created_at,
  talent!inner(
    id,
    first_name,
    last_name
  ),
  created_by:auth_user!contract_created_by_fkey(
    first_name,
    last_name
  ),
  owner:team_member!contract_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  agency(
    name
  ),
  team_member(
    id,
    auth_user(
      first_name,
      last_name
    )
  )
` as const;

export const contractForDocumentViewColumns = `
  id,
  talent_id,
  talent!inner(
    first_name,
    last_name
  ),
  talent_file:file!contract_talent_file_id_fkey(
    ${baseFileColumns}
  ),
  client_file:file!contract_client_file_id_fkey(
    ${baseFileColumns}
  )
` as const;

export const missionColumns = `
  ${baseMissionColumns},
  application(
    id,
    stage,
    talent_id,
    is_rejected,
    has_stage_been_forced
  ),
  company(
    ${baseCompanyColumns}
  ),
  agency(
    ${baseAgencyColumns}
  ),
  business_unit(
    ${baseBusinessUnitColumns}
  ),
  project_typology(
    ${baseProjectTypologyColumns}
  ),
  software(
    ${softwareColumns}
  ),
  qualification_certificate(
    ${baseQualificationCertificateColumns}
  ),
  owner:team_member!mission_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  assignee:team_member!mission_assignee_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  lead_contact:contact!mission_lead_contact_id_fkey(
    ${contactColumns}
  ),
  interviewer_contact:contact!mission_lead_contact_id_fkey(
    ${contactColumns}
  ),
  decision_maker_contact:contact!mission_decision_maker_contact_id_fkey(
    ${contactColumns}
  ),
  workspace!mission_workspace_id_fkey(
    name
  ),
  updated_by:auth_user!mission_updated_by_fkey(
    ${authUserColumns}
  ),
  created_by:auth_user!mission_created_by_fkey(
    ${authUserColumns}
  ),
  mission_workspaces:mission_workspace!inner(
    ${baseMissionWorkspaceColumns}
  )
` as const;

export const missionSearchColumns = `
  id,
  address,
  is_public,
  job_name,
  updated_at,
  company(
    name
  ),
  mission_workspace!inner(
    workspace_id
  )
` as const;

export const missionForRowColumns = `
  id,
  created_at,
  created_by:auth_user!mission_created_by_fkey(
    ${authUserColumns}
  ),
  status,
  job_name,
  start_date,
  job_extra_info,
  number_of_open_positions,
  job_posting_status,
  company(name),
  business_unit(emoji, name),
  address,
  address_extra_info,
  workspace_id,
  is_public,
  owner:team_member!mission_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  assignee:team_member!mission_assignee_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  application(id, stage, is_rejected),
  mission_workspaces:mission_workspace!inner(workspace_id)
` as const;

export const applicationColumns = `
  ${baseApplicationColumns},
  mission!inner(
    id,
    job_name,
    start_date,
    end_date,
    decision_maker_contact_id,
    job_id,
    company_id,
    working_days,
    working_hours,
    address,
    employee_status,
    coefficient,
    workspace_id,
    business_unit_id,
    is_public,
    company(
      id,
      name
    ),
    job(
      name
    ),
    owner:team_member!mission_owner_id_fkey(
      ${teamMemberForAvatarColumns}
    ),
    assignee:team_member!mission_assignee_id_fkey(
      ${teamMemberForAvatarColumns}
    ),
    mission_workspaces:mission_workspace!inner (
      workspace_id
    )
  ),
  talent!inner(
    id,
    first_name,
    last_name,
    email,
    phone_number,
    job_name,
    address_table(
      ${baseAddressTableColumns}
    ),
    experience_level,
    has_driver_license,
    availability_date,
    salary_expectation,
    created_by_user:auth_user!talent_created_by_fkey(
      ${authUserColumns}
    ),
    owner:team_member!talent_owner_id_fkey(
      ${teamMemberForAvatarColumns}
    ),
    hiring_pools:hiring_pool(
      ${hiringPoolColumns}
    ),
    talent_workspaces:talent_workspace!inner (
      workspace_id
    )
  ),
  created_by_team_member:auth_user!application_created_by_fkey(
    team_member(
        ${teamMemberForAvatarColumns}
    )
  ),
  updated_by_team_member:auth_user!application_updated_by_fkey(
    team_member(
        ${teamMemberForAvatarColumns}
    )
  )
` as const;

export const qualificationCertificateColumns = `
  ${baseQualificationCertificateColumns}
` as const;

export const qualificationTrainingRequestColumns = `
  ${baseQualificationTrainingRequestColumns},
  auth_user(
    first_name,
    last_name
  ),
  talent(
    id,
    first_name,
    last_name
  ),
  qualification_certificate(
    ${baseQualificationCertificateColumns}
  ),
  qualification_center(
    name,
    address
  ),
  wsm:team_member!qualification_training_request_wsm_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  owner:team_member!qualification_training_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const qualificationTrainingRequestForTableColumns = `
  id,
  status,
  created_at,
  start_at,
  end_at,
  near_to,
  to_reschedule,
  price,
  request_comment,
  transfer_comment,
  possible_start_date,
  possible_end_date,
  auth_user(
    first_name,
    last_name
  ),
  talent(
    id,
    first_name,
    last_name,
    email
  ),
  qualification_center(
    name
  ),
  qualification_certificate(
    ${baseQualificationCertificateColumns}
  ),
  wsm:team_member!qualification_training_request_wsm_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  owner:team_member!qualification_training_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const qualificationCenterColumns = `
  ${baseQualificationCenterColumns},
  qualification_center_certificate(
    qualification_certificate_id
  )
` as const;

export const independentPaymentRequestColumns = `
  id,
  month,
  amount,
  comment,
  status,
  independent:team_member!inner(
    ${teamMemberForAvatarColumns}
  ),
  file(
    ${baseFileColumns}
  )
` as const;

export const documentColumns = `
  id,
  created_at,
  type,
  files:document_file(
    file(
      ${baseFileColumns}
    )
  ),
  expiry_date,
  docuseal_submission_url,
  docuseal_submission_id,
  docuseal_template_id
` as const;

export const recruitmentOfficeInvoiceRequestForTableColumns = `
  id,
  status,
  annual_wage,
  job_name,
  company!inner(
    id,
    name
  ),
  talent!inner(
    id,
    first_name,
    last_name
  ),
  owner:team_member!inner(
    ${teamMemberColumns}
  ),
  created_at, 
  fee_amount,
  is_fee_percentage
` as const;

export const recruitmentOfficeInvoiceRequestColumns = `
  id,
  status,
  annual_wage,
  fee_comment,
  is_fee_percentage,
  fee_amount,
  start_date,
  comment,
  timeline,
  job_name,
  company!inner(
    ${companyColumns}
  ),
  talent!inner(
    ${baseTalentColumns}
  ),
  owner:team_member!inner(
    ${teamMemberForAvatarColumns}
  ),
  created_at
` as const;

export const recruitmentOfficePaymentColumns = `
  ${baseRecruitmentOfficePaymentColumns},
  recruitment_office_invoice_request!inner(
    ${recruitmentOfficeInvoiceRequestColumns}
  )
` as const;

export const medicalCheckupRequestColumns = `
  ${baseMedicalCheckupRequestColumns},
  medical_center(
    id,
    name,
    address
  ),
  auth_user(
    first_name,
    last_name
  ),
  wsm:team_member!medical_checkup_request_wsm_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  owner:team_member!medical_checkup_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  contract!inner(
    id,
    talent(
      id,
      first_name,
      last_name
    ),
    agency!inner(
      id,
      name
    )
  )
` as const;

export const medicalCheckupRequestForTableColumns = `
  ${baseMedicalCheckupRequestColumns},
  auth_user!inner(
    id,
    first_name,
    last_name
  ),
  medical_center(
    id,
    name,
    address
  ),
  talent!inner(
    id,
    first_name,
    last_name
  ),
  contract!inner(
    id,
    agency!inner(
      id,
      name
    ),
    address
  ),
  owner:team_member!medical_checkup_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const medicalCenterColumns = `
  ${baseMedicalCenterColumns}
` as const;

export const talentForProfessionalCardRequestColumns = `
  id,
  first_name,
  last_name,
  email,
  nationality,
  birth_date,
  gender,
  city_of_birth,
  country_of_birth,
  residency_permit_number,
  picture_id,
  professional_card_number
` as const;

export const professionalCardRequestColumns = `
  ${baseProfessionalCardRequestColumns},
  talent!inner(
    ${talentForProfessionalCardRequestColumns}
  ),
  auth_user!inner(
    first_name,
    last_name
  ),
  owner:team_member!professional_card_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const expenseClaimRequestColumns = `
  ${baseExpenseClaimRequestColumns},
  auth_user(
    first_name,
    last_name
  ),
  talent!inner(
    id,
    first_name,
    last_name
  ),
  owner:team_member!expense_claim_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const advancePaymentRequestColumns = `
  ${baseAdvancePaymentRequestColumns},
  auth_user(
    first_name,
    last_name
  ),
  talent!inner(
    id,
    first_name,
    last_name,
    email
  ),
  contract(
    id,
    agency!inner(
      id,
      name,
      company(
        name
      )
    )
  ),
  owner:team_member!advance_payment_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const ppeRequestColumns = `
  ${basePpeRequestColumns},
  auth_user(
    first_name,
    last_name
  ),
  contract!inner(
    id,
    talent!inner(
      id,
      first_name,
      last_name,
      email
    )
  ),
  wsm:team_member!ppe_request_wsm_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  owner:team_member!ppe_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  ppes:ppe_request_item!ppe_request_item_request_id_fkey(
    id,
    attribute,
    equipment:ppe_item!inner(
     name:equipment
    )
  )
` as const;

export const projectTypologyColumns = `
  ${baseProjectTypologyColumns}
` as const;

export const specialtyColumns = `
  ${baseSpecialtyColumns}
` as const;

export const noteColumns = `
  ${baseNoteColumns},
  updated_by:auth_user!note_updated_by_fkey(
    ${authUserColumns}
  ),
  created_by:auth_user!note_created_by_fkey(
    ${authUserColumns}
  ),
  created_by_roles:auth_user!note_created_by_fkey(
    user_role(
      role
    )
  )
` as const;

export const notificationColumns = `
  ${baseNotificationColumns}
` as const;

export const contractForTimesheetColumns = `
  job_name,
  talent(
    id,
    first_name,
    last_name,
    email,
    phone_number
  ),
  agency(
    name,
    company(
      name
    )
  )
` as const;

export const contractForTimesheetLinkColumns = `
  created_by:auth_user!contract_created_by_fkey!inner(
    first_name,
    last_name,
    phone_number,
    email
  ),
  talent(
    first_name,
    last_name
  )
` as const;

export const contractForSelectTimesheetColumns = `
  id,
  contract_key,
  start_date,
  end_date,
  real_end_date,
  timesheet_launch_date,
  amendment_type,
  working_days,
  talent!inner(
    id,
    first_name,
    last_name
  )
` as const;

export const timesheetSignerColumns = `
  is_worksite_signer,
  contact!inner(
    id,
    email
  )
` as const;

export const commercialConditionColumns = `
  ${baseCommercialConditionColumns},
  company(
    id,
    name
  ),
  auth_user!inner(
    id,
    team_member!inner(
      id,
      workspace_id,
      manager_id
    )
  ),
  generated_file:file!commercial_condition_generated_file_id_fkey(
    ${baseFileColumns}
  ),
  signed_file:file!commercial_condition_signed_file_id_fkey(
    ${baseFileColumns}
  ),
  created_by:auth_user!commercial_condition_created_by_fkey(
    ${authUserColumns}
  ),
  business_unit(
    ${baseBusinessUnitColumns}
  )
` as const;

export const timesheetWeekColumns = `
  status,
  contract_key,
  date
` as const;

export const companyInsuranceRequestColumns = `*,
  company(
    name
  ),
  owner:team_member(
    ${teamMemberForAvatarColumns}
  ),
  document(
    ${documentColumns}
  )
` as const;

export const viewContractColumns = `
  *,
  owner:team_member!contract_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  talent!inner(
    id,
    first_name,
    last_name,
    has_auth_access
  ),
  agency(
    name,
    company(
      name
    )
  )
` as const;

export const userForTableColumns = `
  ${authUserColumns},
  user_roles:user_role(
    role
  ),
  team_member!inner(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const cooptationForTableColumns = `
  *,
  owner:team_member!cooptation_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  coopted_by:talent!cooptation_coopted_by_id_fkey(
    id,
    first_name,
    last_name
  ),
  coopted:talent!cooptation_coopted_id_fkey(
    id,
    first_name,
    last_name
  )
` as const;

export const cooptationDetailsColumns = `
  *,
   owner:team_member!cooptation_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  ),
  coopted:talent!cooptation_coopted_id_fkey(
    id,
    first_name,
    last_name
  ),
  coopted_by:talent!cooptation_coopted_by_id_fkey(
    id,
    first_name,
    last_name
  )
` as const;

export const cooptationColumns = `
  id,
  is_bonus_eligible
` as const;

export const talentProjectTypologyColumns = `
  ${baseTalentProjectTypologyColumns}
` as const;

export const smsColumns = `
  id,
  talent_id,
  send_at,
  content,
  is_sent,
  auth_user(
    team_member (
      ${teamMemberForAvatarColumns}
    )
  ),
  canceled_at
` as const;

export const substituteBenchColumns = `
  contract_id,
  business_unit_id,
  business_unit_name,
  owner_id,
  owner_first_name,
  owner_last_name,
  talent_id,
  talent_first_name,
  talent_last_name,
  definitive_end_details,
  definitive_end_type,
  start_date, end_date,
  job_name,
  next_contract_start_date,
  days_without_contract,
  ongoing_applications_count,
  days_on_bench,
  address_postal_code,
  address_city,
  last_contract_company_name,
  is_marketing_action,
  talent_availability_status,
  talent_availability_status_updated_at,
  workspace_id
` as const;

export const workAccidentRequestColumns = `
  *,
  contract!inner(
    start_date,
    end_date,
    id,
    talent(
      id,
      first_name,
      last_name,
      has_auth_access
    ),
    agency!inner(
      id,
      name
    )
  ),
  owner:team_member!work_accident_request_owner_id_fkey(
    ${teamMemberForAvatarColumns}
  )
` as const;

export const emailColumns = `
  ${baseEmailColumns},
  owner:team_member!email_owner_id_fkey(${teamMemberForAvatarColumns}
  ),
  auth_user(
    team_member (
      ${teamMemberForAvatarColumns}
    )
  )
` as const;

export const regionColumns = `
  ${baseRegionColumns}
` as const;

export const departmentColumns = `
  ${baseDepartmentColumns},
  region (
    name
  )
` as const;

export const talentPushMarketingRequestColumns = `
  ${baseTalentPushMarketingRequestColumns},
  created_by_team_member:auth_user!talent_push_marketing_request_created_by_fkey(
    team_member(
      ${teamMemberForAvatarColumns}
    )
  ),
  updated_by_team_member:auth_user!talent_push_marketing_request_updated_by_fkey(
    team_member(
      ${teamMemberForAvatarColumns}
    )
  )
` as const;

export const talentPushMarketingRequestWithTalentColumns = `
  ${baseTalentPushMarketingRequestColumns},
  talent(
          first_name,
          last_name,
          job_name,
          address_table(
            department(
              name,
              local_code,
              region(name)
      )
    )
  )
` as const;

export const applicationOmogenColumns = `${applicationOmogenBaseColumns}` as const;
