import { z } from "zod";

export const payTrackingTalentSchema = z.object({
  id: z.string(), // OK
  first_name: z.string(), // OK
  last_name: z.string(), // OK
  email: z.string(), // OK
});

export const payTrackingAccountingSchema = z.object({
  id: z.string().nullable(), // OK
  first_name: z.string().nullable(), // OK
  last_name: z.string().nullable(), // OK
  email: z.string().nullable(), // OK
});

export const payTrackingConsultantSchema = z.object({
  id: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  email: z.string().nullable(),
});
