import { z } from "zod";
import { MonthSupabaseEnum, PayTrackingStatusSupabaseEnum } from "../../types/supabase.enum.gen.ts";
import { payTrackingAccountingSchema, payTrackingConsultantSchema, payTrackingTalentSchema } from "./shared.schema.ts";

export const payTrackingListFilterSchema = z.object({
  // TODO: change this with Julien implementation
  month: z.preprocess((arg) => {
    if (typeof arg === "string" || arg instanceof Date) {
      return new Date(arg);
    }
    return arg;
  }, z.date()),
  consultants: z
    .preprocess((arg) => {
      if (typeof arg === "string") {
        return arg.split(",");
      }
      return arg;
    }, z.array(z.string()))
    .optional(),
  admins: z
    .preprocess((arg) => {
      if (typeof arg === "string") {
        return arg.split(",");
      }
      return arg;
    }, z.array(z.string()))
    .optional(),
  statuses: z
    .preprocess(
      (arg) => {
        if (typeof arg === "string") {
          return arg.split(",");
        }
        return arg;
      },
      z.array(z.nativeEnum(PayTrackingStatusSupabaseEnum))
    )
    .optional(),
  search: z.string().optional(),
});
export const payTrackingListContractSchema = z.object({
  contract_key: z.number(),
  start_date: z.string(),
  end_date: z.string(),
});

export const payTrackingListTimesheetWeekSchema = z.object({
  week_number: z.number().nonnegative(),
  is_worked: z.boolean(),
  total_minutes: z.number().nonnegative(),
});

export const payTrackingListAdminRequestSchema = z.object({
  has_admin_requests: z.boolean(),
  expense_claim_request_count: z.number().nonnegative(),
  qualification_training_request_count: z.number().nonnegative(),
  advance_payment_request_count: z.number().nonnegative(),
});

export const payTrackingListRowSchema = z.object({
  id: z.string(),
  month: z.nativeEnum(MonthSupabaseEnum),
  talent: payTrackingTalentSchema,
  accounting: payTrackingAccountingSchema,
  status: z.nativeEnum(PayTrackingStatusSupabaseEnum),
  consultants: z.array(payTrackingConsultantSchema),
  contracts: z.array(payTrackingListContractSchema),
  specificities: z.array(z.string()),
  timesheet_weeks: z.array(payTrackingListTimesheetWeekSchema),
  admin_requests: payTrackingListAdminRequestSchema,
  total_worked_minutes: z.number().nonnegative(),
});

export const payTrackingListSchema = z.array(payTrackingListRowSchema);
